import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Tooltip,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useCallback, useEffect, useState } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';

import dayjs from 'dayjs';
import { useApi } from '../../hooks/api';
import IClientsList from '../../interfaces/clients';
import IIntegrationForm from '../../interfaces/integrationForm';
import './styles.scss';
import ILicenseList from '../../interfaces/license';
import { notificationDCS } from '../../utils/notificationDcs';
import PhoneInput from '../PhoneInput';
import { medias } from '../../constants/medias';

const { Option } = Select;

interface IParams {
  id: string;
}

interface IBroker {
  id: string;
  broker: string;
}

interface ICredenctials {
  [key: string]: string;
}

interface IData {
  name: string;
  media_name: string;
  broker: string;
  credentials: string;
  client_id: string;
  license_id?: string;
  account?: string;
  clientIndirect?: boolean;
  url_callback?: string;
  url_status_whatsapp?: string;
  citruscx_callback_created?: string;
}

interface IProps {
  saveIntegration(data: IIntegrationForm): Promise<void>;
}

const IntegrationForm: React.FC<IProps> = props => {
  const { saveIntegration } = props;
  const history = useHistory();
  const { api } = useApi();
  const { id } = useParams<IParams>();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [brokers, setBrokers] = useState<IBroker[]>([]);
  const [configs, setConfigs] = useState<string[]>([]);
  const [showAccountInput, setShowAccountInput] = useState(false);
  const [showLicenseInput, setShowLicenseInput] = useState(false);
  const [, setAccountLabel] = useState('');
  const [clients, setClients] = useState<IClientsList[]>([]);
  const [clientIsIndirect, setClientIsIndirect] = useState<boolean>(false);
  const [clientIdSelected, setClientIdSelected] = useState<string>('');
  const [licenses, setLicenses] = useState<ILicenseList[]>([]);
  const [showInputUrlforHsm, setShowInputUrlforHsm] = useState(false);
  const [showInputUrlforFacebookMsg, setShowInputUrlforFacebookMsg] =
    useState(false);
  const [media, setMedia] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<any>('');

  const back = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleShowUrlsCallback = (value: string) => {
    setShowInputUrlforFacebookMsg(false);
    setShowInputUrlforHsm(false);
    if (value === 'whatsapp') {
      setShowInputUrlforHsm(true);
    } else if (value === 'fbmessenger') {
      setShowInputUrlforFacebookMsg(true);
    }
  };

  const validateForm = async () => {
    try {
      await form.validateFields();
    } catch (err) {
      notificationDCS({
        type: 'warning',
        message: 'Alerta',
        description: 'Por favor, preencha todos os campos obrigatórios!',
        secondsDuration: 2,
      });
    }
  };

  const onSave = useCallback(async () => {
    setLoading(true);
    try {
      const credentials: ICredenctials = {};

      configs.forEach(item => {
        let value = form.getFieldValue(item);

        if (item === 'data-limite' && value) {
          if (dayjs.isDayjs(value)) {
            value = value.format('YYYY-MM-DD');
          } else {
            value = dayjs(value).format('YYYY-MM-DD');
          }
        }

        credentials[item] = value;
      });

      const data: IData = {
        name: form.getFieldValue('name'),
        media_name: form.getFieldValue('media_name'),
        broker: form.getFieldValue('broker'),
        client_id: form.getFieldValue('client_id'),
        license_id: form.getFieldValue('license_id'),
        credentials: JSON.stringify(credentials),
        clientIndirect: clientIsIndirect,
        url_callback: form.getFieldValue('url_callback'),
        url_status_whatsapp: form.getFieldValue('url_status_whatsapp'),
        citruscx_callback_created: form.getFieldValue(
          'citruscx_callback_created',
        ),
        account: form.getFieldValue('account'),
      };

      await saveIntegration(data);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [configs, form, clientIsIndirect, saveIntegration]);

  const loadBrokers = useCallback(
    async (media_name: string) => {
      form.setFieldsValue({
        [`broker`]: '',
      });

      const response = await api.get(
        `/integrations-config/brokers/${media_name}`,
      );

      setBrokers(response.data);
      setAccountLabel(
        media_name === 'whatsapp' ? 'Número do celular:' : 'Nome da conta:',
      );

      setShowAccountInput(
        ['whatsapp', 'fbmessenger', 'fbcomments', 'igdirect'].includes(
          media_name,
        ),
      );

      if (media_name === 'whatsapp') setAccountLabel('Número do celular:');
      else setAccountLabel('Nome da Conta:');

      handleShowUrlsCallback(media_name);
    },
    [api, form],
  );

  const loadConfigs = useCallback(
    async (media_name: string, broker: string) => {
      const response = await api.get(
        `/integrations-config/configurations/${media_name}/${broker}`,
      );
      setConfigs(response.data.configs);
    },
    [api],
  );

  const formatString = useCallback((text: string) => {
    const words = text.split('_');
    const newWords = words.map(
      item => item.charAt(0).toUpperCase() + item.substr(1),
    );
    return newWords.join(' ');
  }, []);

  const formatSendIdMessage = (text: string) => {
    const medias = ['fbmessenger', 'fbcomments', 'igdirect', 'igcomments'];

    if (medias.includes(media) && text === 'Sender-id') {
      return (
        <>
          {text}{' '}
          <Tooltip title="Para os canais Facebook e Instagram, essa informação corresponde ao ID da página.">
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      );
    }
    return text;
  };

  const loadLicenses = useCallback(
    async (media: string) => {
      if (media === 'sms' || media === 'whatsapp') {
        const clientId = form.getFieldValue('client_id');
        if (clientId) {
          const response = await api.get(`/licenses/${clientId}/${media}`);
          if (response.data.length === 0) {
            notificationDCS({
              type: 'warning',
              message: 'Atenção',
              description: 'Não existe licença para esse cliente/canal!',
              secondsDuration: 3,
            });
          }
          setLicenses(response.data);
          setShowLicenseInput(true);
        }
      } else {
        setLicenses([]);
        setShowLicenseInput(false);
      }
    },
    [api],
  );

  const loadIntegrationDetail = useCallback(async () => {
    const response = await api.get(`/integrations/${id}`);

    const credentials = JSON.parse(response.data.credentials || '{}');
    const credentialsKeys = Object.keys(credentials);

    credentialsKeys.forEach(item => {
      let value = credentials[item];

      // ✅ Converte "data-limite" para dayjs antes de setar no formulário
      if (item === 'data-limite' && value) {
        value = dayjs(value);
      }

      form.setFieldsValue({
        [item]: value,
      });
    });

    await loadBrokers(response.data.media_name);
    await loadConfigs(response.data.media_name, response.data.broker);
    form.setFieldsValue(response.data);
  }, [api, id, form]);

  const loadClients = useCallback(async () => {
    const response = await api.get('/clients/actives');
    setClients(response.data);
  }, [api]);

  const handleChangeClient = useCallback(
    (client_id: string) => {
      const client = clients.find((c: IClientsList) => c.id === client_id);
      if (client) {
        setClientIsIndirect(client.client_type === 'INDIRECT');
      }

      if (media) {
        loadLicenses(media);
      }
    },
    [clients, loadLicenses, media],
  );

  const handleChangeMedia = (media: string) => {
    loadLicenses(media);
    setMedia(media);
    loadBrokers(media);
  };

  useEffect(() => {
    if (id) loadIntegrationDetail();
    loadClients();
  }, [id, loadClients, loadIntegrationDetail]);

  const isWhatsAppOrSMS = ['whatsapp', 'sms'].includes(
    form.getFieldValue('media_name'),
  );

  return (
    <>
      {clientIsIndirect && !id && (
        <Alert
          style={{ marginTop: '5px' }}
          message="Integração cadastrada com cliente do tipo indireto, selecione uma licença correspondente ao cliente parceiro."
          type="warning"
          className="ant-alert-message"
        />
      )}
      <Form layout="vertical" form={form} onFinish={onSave}>
        <Row>
          {id && (
            <Col span={24}>
              <Form.Item
                label="Integration ID:"
                name="id"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input
                  disabled
                  className="dcs-input"
                  placeholder="ex.: Id da Integração"
                />
              </Form.Item>
            </Col>
          )}
          <Col span={24}>
            <Form.Item
              label="Nome da Integração:"
              name="name"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Input
                className="dcs-input"
                placeholder="ex.: Nome da Integração"
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="Cliente:"
              name="client_id"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Select
                allowClear
                showSearch
                placeholder="Selecione um cliente"
                filterOption
                optionFilterProp="children"
                className="dcs-select"
                onChange={handleChangeClient}
                disabled={id !== undefined}
              >
                {clients.map(client => (
                  <Option key={client.id} value={client.id}>
                    {client.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Canal:"
              name="media_name"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Select
                allowClear
                showSearch
                placeholder="Canal"
                filterOption
                optionFilterProp="children"
                className="dcs-select"
                onChange={value => handleChangeMedia(String(value))}
                disabled={id !== undefined}
              >
                {medias.map(media => (
                  <Option key={media.value} value={media.value}>
                    {media.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {showLicenseInput && (
            <Col span={24}>
              <Form.Item
                label={
                  <span>
                    Licença:&nbsp;
                    <Tooltip title="Deve selecionar um cliente e um canal para listar as licenças!">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                name="license_id"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Selecione uma licença"
                  filterOption
                  optionFilterProp="children"
                  className="dcs-select"
                  onChange={handleChangeClient}
                  disabled={id !== undefined}
                >
                  {licenses.map(license => (
                    <Option key={license.id} value={license.id}>
                      {license.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}

          <Col span={24}>
            <Form.Item
              label="Broker:"
              name="broker"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Select
                allowClear
                showSearch
                placeholder="Broker"
                filterOption
                optionFilterProp="children"
                className="dcs-select"
                onChange={value =>
                  loadConfigs(form.getFieldValue('media_name'), String(value))
                }
                disabled={id !== undefined}
              >
                {brokers.map(item => (
                  <Option key={item.id} value={item.broker}>
                    {item.broker.toUpperCase()}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={
                <span>
                  Conta/Telefone:&nbsp;
                  <Tooltip title="Essa informação indica o número ou nome da conta cadastrada no canal.">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="account"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              {isWhatsAppOrSMS ? (
                <PhoneInput onChange={e => setPhoneNumber(e)} />
              ) : (
                <Input className="dcs-input" placeholder="Ex.: usuário" />
              )}
            </Form.Item>
          </Col>
          {configs.length > 0 && (
            <Col span={24}>
              <Divider orientation="left">Configuração do Broker</Divider>
              {configs.map(config => (
                <Form.Item
                  key={config}
                  label={
                    <span>{formatSendIdMessage(formatString(config))}</span>
                  }
                  name={config}
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                  {config === 'data-limite' ? (
                    <DatePicker
                      className="dcs-input"
                      format="DD/MM/YYYY"
                      placeholder="Selecione a data"
                      value={
                        form.getFieldValue(config)
                          ? dayjs(form.getFieldValue(config)) // ✅ Garante que sempre será um `dayjs`
                          : null
                      }
                      onChange={date => form.setFieldsValue({ [config]: date })}
                    />
                  ) : (
                    <Input
                      className="dcs-input"
                      placeholder={`ex.: ${config}`}
                    />
                  )}
                </Form.Item>
              ))}
            </Col>
          )}
          {form.getFieldValue('broker') !== 'govbr' && (
            <Col span={24}>
              <Divider orientation="left">
                Configuração de URL de Callback
              </Divider>
              <Form.Item
                label={
                  <span>
                    URL de Callback de Mensagem:&nbsp;
                    <Tooltip title="Está é a url de callback padrão para recebimento das mensagens do DCS.">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                name="url_callback"
              >
                <Input
                  className="dcs-input"
                  placeholder="ex.: www.urlcallback.com/callback"
                />
              </Form.Item>
            </Col>
          )}
          <Col span={24}>
            {showInputUrlforHsm && (
              <Form.Item
                label={
                  <span>
                    URL de Status de Entrega de Mensagem WhatsApp:&nbsp;
                    <Tooltip title="Esta é a URL de callback para o recebimento do status de entrega das mensagens enviadas para os contatos do cliente.">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                name="url_status_whatsapp"
              >
                <Input
                  className="dcs-input"
                  placeholder="ex.: www.urlcallback.com/url_status_whatsapp"
                />
              </Form.Item>
            )}
            {showInputUrlforFacebookMsg && (
              <Form.Item
                label={
                  <span>
                    URL Callback Confirmação de Cadastro:&nbsp;
                    <Tooltip title="Está é a url de callback para confirmação de conta do Facebook Messenger.">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                name="citruscx_callback_created"
              >
                <Input
                  className="dcs-input"
                  placeholder="ex.: www.urlcallback.com/callback-confirm-account"
                />
              </Form.Item>
            )}
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col>
            <Button
              type="primary"
              shape="round"
              icon={<FaSave />}
              htmlType="submit"
              loading={loading}
              onClick={validateForm}
            >
              Salvar
            </Button>
          </Col>
          <Col>
            <Button shape="round" icon={<FaTimes />} onClick={back}>
              Cancelar
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default IntegrationForm;
